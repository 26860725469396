@import "~antd/dist/reset.css";

@font-face {
	src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
	font-family: poppins;
}

@font-face {
	src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
	font-family: poppins-bold;
}
